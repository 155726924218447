// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

import "./src/css/global.css"

export const onRouteUpdate = () => {
  var body = document.body
  var details = document.querySelector("details")

  if (body.classList.contains("overflow-hidden")) {
    body.classList.remove("overflow-hidden")
  }

  details.addEventListener("toggle", function () {
    if (details.hasAttribute("open")) {
      body.classList.add("overflow-hidden")
    } else if (!details.hasAttribute("open")) {
      body.classList.remove("overflow-hidden")
    }
  })
}
